<template>
  <v-row class="cw-loan-offer-info">
    <v-col cols="12">
      <p
        v-translate
        class="mb-0"
      >
        We have calculated the following personalized offers. Please choose an offer to continue
        applying for a loan.
      </p>
    </v-col>

    <v-col
      v-if="!isAppliedProduct"
      class="pb-2"
      cols="12"
    >
      <div class="cw-loan-offer-info__product">
        <v-card
          v-for="product in products"
          :key="product.type"
          :class="{ 'elevation-8': product.active }"
          :outlined="!product.active"
          class="cw-loan-offer-info__product-card"
          height="100%"
        >
          <v-card-title class="text-subtitle-1 font-weight-medium info--text">
            {{ product.title }}
          </v-card-title>

          <v-card-text>
            {{ product.text }}
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import productInfo from '@shared/components/data/productInfo';

export default {
  name: 'CwLoanOfferInfo',

  props: {
    isAppliedProduct: {
      type: Boolean,
      default: false,
    },

    isDownsell: {
      type: Boolean,
      default: false,
    },

    offerTypes: {
      type: Array,
      default: () => [],
    },

    selectedOfferType: {
      type: String,
      default: '',
    },
  },

  computed: {
    products() {
      return productInfo
        .filter(product => this.offerTypes.includes(product.type))
        .map(product => ({
          ...product,
          active: this.selectedOfferType === product.type,
        }));
    },
  },
};
</script>

<style lang="scss">
.cw-loan-offer-info {
  padding-bottom: 16px !important;

  @include mq($from: xs) {
    padding-bottom: 24px !important;
  }

  @include mq($from: md) {
    padding-bottom: 32px !important;
  }

  &__product {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;

    @include mq($from: md) {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-gap: 16px;
    }
  }
}
</style>
