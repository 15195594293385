import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export default [{
  type: 'continuousLoan',
  title: $gettext('Flexible credit'),
  text: $gettext('The principle of the credit is that it works exactly the way you want it to. You can withdraw as much as you need and only pay for what you use. Monthly instalments depend on the loan used. Set the amount of your first withdrawal on the next page.'),
}, {
  type: 'instalmentLoan',
  title: $gettext('Consumer credit'),
  text: $gettext('You can transfer the entire loan to your account as soon as you receive the loan decision. You can pay back in monthly instalments. Choose the appropriate monthly instalment and loan period. You can also pay off your consumer credit at once at any point in your payment program.'),
}, {
  type: 'fixedPeriodContinuousLoan',
  title: $gettext('500 € Flexible credit'),
  text: $gettext('This loan works exactly the way you want it to: you can transfer the whole 500 € to your account or withdraw a smaller sum. You have 45 days to make withdrawals from the 500 € credit limit. The first invoice is sent after the 45 day period.'),
}];
